<template>
  <div>     
    <vs-popup title="Registrar Ocorrência" :active.sync="popUpOcorrencia.exibir" v-if="popUpOcorrencia.exibir">
      <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
      <div class="vx-row mt-2">
        <div class="vx-col w-1/2">
            <label class="vs-input--label">Data/Hora do Contato</label>
            <flat-pickr v-model="popUpOcorrencia.dataHoraContatoComCliente" :config="configDateTimePicker" placeholder="Data"/>
        </div>
        <div class="vx-col w-1/2">   
            <label class="vs-input--label">Em manutenção ?</label>
            <v-select                             
                :options="listaOpcoesSIM_NAO"                   
                :reduce="tipo => tipo.data" 
                v-model="popUpOcorrencia.emManutencao"       
                class="v-select">
            </v-select>                                           
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-1/2">        
            <label class="vs-input--label">Nome do Contato</label>
            <vs-input class="w-full" v-model="popUpOcorrencia.nomeContato" maxlength="100"/>              
        </div>
        <div class="vx-col w-1/2">
            <label class="vs-input--label">Telefone do Contato</label>
            <vs-input class="w-full" v-model="popUpOcorrencia.telefoneContato" v-mask="['(##) #####-####', '(##) ####-####']"/>        
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
            <label class="vs-input--label">Descrição</label>
            <vs-textarea v-model="popUpOcorrencia.descricao" counter="1000" />
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
            <label class="vs-input--label">Tratativa</label>
            <vs-textarea v-model="popUpOcorrencia.tratativa" counter="1000" />
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
            <label class="vs-input--label">Resposta Cliente</label>
            <vs-textarea v-model="popUpOcorrencia.respostaCliente" counter="1000" />
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-1/2">
            <label class="vs-input--label">Retirar do relatório até:</label>
            <flat-pickr v-model="popUpOcorrencia.desconsiderarAte" :config="configDatePicker" placeholder="Data"/>
        </div>
      </div>
      <div class="vx-row mt-5">
        <div class="vx-col w-full">
            <vs-button class="w-full" @click="gravar" color="success">Gravar</vs-button>            
        </div>
        
      </div>
    </vs-popup>
    <vs-popup title="Ocorrências do veículo" :active.sync="popUpListaOcorrencia.exibir" v-if="popUpListaOcorrencia.exibir">
            <vs-table :data="popUpListaOcorrencia.listaUltimasOcorrencias" class="tabelaComScroll w-full mr-4" noDataText="..." search hoverFlat stripe>
                <template slot="header">
                    <h5 class="ml-2 mt-2 mr-4 ">PLACA: {{popUpListaOcorrencia.placaSelecionada}}</h5><h6 class="ml-2 mt-2 mr-4 " v-if="popUpListaOcorrencia.listaUltimasOcorrencias.length > 0 "> - {{popUpListaOcorrencia.listaUltimasOcorrencias.length}} ocorrências</h6>                       
                </template>
                <template slot="thead">
                    <vs-th></vs-th>                    
                    <vs-th class="p-0">Cadastro</vs-th>                    
                    <vs-th class="p-0">Descrição</vs-th>                    
                    <vs-th class="p-0">Data Contato</vs-th>                    
                </template>
                <template slot-scope="{data}">
                    <vs-tr  :data="tr" :key="indextr" v-for="(tr, indextr) in data" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">                        
                        <vs-td><small> {{(indextr+1)}} </small></vs-td>                        
                        <vs-td class="p-0"><small class="font-bold">{{tr.dataHoraCadastroFormatada}}</small></vs-td>
                        <vs-td class="p-0"><small>{{tr.descricao}}</small></vs-td>
                        <vs-td class="p-0"><small> {{tr.dataHoraContatoComClienteFormatada}} </small></vs-td>                       
                    </vs-tr>
                </template>
            </vs-table>
    </vs-popup>
    <vx-card class="mb-2">
      <div class="w-full flex items-center" >
        <label class="vs-input--label">Horas sem transmitir:</label> <vs-input class="ml-2 mr-2" placeholder="Digite o número de horas" v-model="numeroHoras" @keyup.enter.native="fetchLista" />
        
        <label class="vs-input--label">Exibir: </label>
        <v-select                             
            :options="listaOpcoesExibir"                   
            :reduce="tipo => tipo.data" 
            v-model="exibirDesconsideradosAte" 
            style="width: 290px"      
            class="v-select ml-2">
        </v-select>                                    
        <label class="vs-input--label">Cliente: </label>   
        <vs-input class="ml-2 mr-2"  v-model="nomeCliente" @keyup.enter.native="fetchLista"/>

        <vx-tooltip text="Pesquisar">  
            <vs-button class="ml-2" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchLista()" :disabled="!numeroHoras"></vs-button>        
        </vx-tooltip>
        
        <label class="vs-input--label text-success ml-auto">Veículos selecionados:</label> <h6 class="ml-4 mr-4">{{qtdRegistrosSelecionados }} </h6>
        <vx-tooltip text="Registrar ocorrência em lote.">  
          <vs-button v-if="qtdRegistrosSelecionados" color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-alert-circle" @click="exibirPopUpRegistroOcorrencia"></vs-button>          
        </vx-tooltip>

        <label class="vs-input--label text-danger ">Registros:</label> <h6 class="ml-4 mr-4">{{dadosDaGrid.length }} </h6>
        <vx-tooltip text="Exportar pesquisa para arquivo CSV.">  
          <vs-button color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV"></vs-button>          
        </vx-tooltip>
      </div>
      <div class="w-full flex items-center mt-2">
        <vx-tooltip text="Listar ultimas ocorrências do veículo.">  
          <vs-button v-if="qtdRegistrosSelecionados == 1" size="small" color="primary" type="filled" icon-pack="feather" icon="icon-list" @click="exibirPopUpListaOcorrencias"> Ultima Ocorrências</vs-button>          
        </vx-tooltip>
        
      </div>
    </vx-card>

    <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-theme-material ag-grid-table ag-grid-table-com-selecao ag-grid-altura mt-1"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="dadosDaGrid"
      :rowHeight="60"
      :headerHeight="25"        
      :rowSelection="tipoSelecaoLinha"            
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
       @grid-ready="onGridReady"
      @selection-changed="onSelectionChanged"
      >
    </ag-grid-vue> 
    
  </div>

</template>

<script>
import generico from '@/generico.js'
import axios from "@/axios.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';

const URL_SERVLET = "/RelatorioVeiculosComProblema";

import { CONSTANTE_OPCAO_SIM_NAO } from "@/constantesComboBox.js";

const CONSTANTE_OPCAO_EXIBIR = [	
  {label:"TODOS", data:""},			
	{label:"VEÍCULOS RETIRADOS", data:"SIM"},			
	{label:"SEM DESCONSIDERADOS", data:"NAO"}
];

export default {
  components: {
    flatPickr,
    AgGridVue
  },
  props: {
    filtroHoras: {
      type: String,
      required: false,
      default: null
    }   
  },
  data() {
    return {
      errorsValidacao: [],  
      numeroHoras: 24,
      exibirDesconsideradosAte: "NAO",
      nomeCliente: null,
      dadosDaGrid: [],      
      configuracaoGrid: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [        
            { headerName: 'Veículo', field: 'codigo' ,
              cellRenderer: function(params) {
                let dadosVeiculos = (params.data.modelo === 'Outro' ? params.data.modeloOutro : params.data.modelo) + ' ' + params.data.placa + '<br/>';
                if (params.data.hasOwnProperty('celular1Aviso')) {
                    dadosVeiculos = dadosVeiculos  + '<small>' + params.data.celular1Aviso;
                    if (params.data.hasOwnProperty('nomeContatoResponsavel1')) {
                      dadosVeiculos = dadosVeiculos + ' - ' + params.data.nomeContatoResponsavel1;
                    }
                    dadosVeiculos = dadosVeiculos  + '</small>';
                }
                if (params.data.hasOwnProperty('celular2Aviso')) {
                    if (params.data.hasOwnProperty('celular1Aviso')) {
                      dadosVeiculos = dadosVeiculos  + '<br/>';
                    }
                    dadosVeiculos = dadosVeiculos   + '<small>' + params.data.celular2Aviso;
                    if (params.data.hasOwnProperty('nomeContatoResponsavel2')) {
                      dadosVeiculos = dadosVeiculos + ' - ' + params.data.nomeContatoResponsavel2;
                    }
                    dadosVeiculos = dadosVeiculos  + '</small>';
                }
                return dadosVeiculos;
              },
              valueGetter: function(params) {
                let dadosVeiculos = (params.data.modelo === 'Outro' ? params.data.modeloOutro : params.data.modelo) + ' ' + params.data.placa;
                if (params.data.hasOwnProperty('celular1Aviso')) {
                    dadosVeiculos = dadosVeiculos + " / "  + params.data.celular1Aviso;
                    if (params.data.hasOwnProperty('nomeContatoResponsavel1')) {
                      dadosVeiculos = dadosVeiculos + ' - ' + params.data.nomeContatoResponsavel1;
                    }                    
                }

                if (params.data.hasOwnProperty('celular2Aviso')) {                    
                    dadosVeiculos = dadosVeiculos + params.data.celular2Aviso;
                    if (params.data.hasOwnProperty('nomeContatoResponsavel2')) {
                      dadosVeiculos = dadosVeiculos + ' - ' + params.data.nomeContatoResponsavel2;
                    }                    
                }
                return dadosVeiculos;
              }
            },           
            { headerName: 'Rastreador', field: 'codigoModulo',
              cellRenderer: function(params) {
                return params.data.codigoModulo + '<br/>' + params.data.numeroGSM + '-' + params.data.operadoraGSM;
              },
               valueGetter: function(params) {
                return params.data.codigoModulo + ' - ' + params.data.numeroGSM + '-' + params.data.operadoraGSM;
              },
            },           
            { headerName: 'Cliente', field: 'clienteNome',
                cellRenderer: function(params) {
                  let dadosCliente = params.data.clienteNome;

                  if (params.data.hasOwnProperty('nomeResponsavelFrota')) {   
                        dadosCliente = dadosCliente + "<br/> <small> " + params.data.nomeResponsavelFrota + " - " + params.data.telefonesResponsavelFrota + "</small>"
                  }
                  return dadosCliente;
                },
                valueGetter: function(params) {
                   let dadosCliente = params.data.clienteNome;

                  if (params.data.hasOwnProperty('nomeResponsavelFrota')) {   
                        dadosCliente = dadosCliente + " / " + params.data.nomeResponsavelFrota + " - " + params.data.telefonesResponsavelFrota
                  }
                  return dadosCliente;
                }
            },           
            { headerName: 'Ultima Atualização', field: 'dataUltimaAtualizacaoEmMili',
              cellRenderer: function(params) {
                return params.data.dataUltimaAtualizacao + '<br/>' + params.data.tempoSemAtualizacao;
              },
              valueGetter: function(params) {
                return params.data.dataUltimaAtualizacao
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                var res = nodeA.data.dataUltimaAtualizacaoEmMili == nodeB.data.dataUltimaAtualizacaoEmMili ? 0 : nodeA.data.dataUltimaAtualizacaoEmMili > nodeB.data.dataUltimaAtualizacaoEmMili ? 1 : -1;
                return res;
              },             
            },    
             { headerName: 'Ultima Comunicação', field: 'dataUltimaComunicacaoEmMili',
              cellRenderer: function(params) {
                return params.data.dataUltimaComunicacao;
              },
              valueGetter: function(params) {
                return params.data.dataUltimaComunicacao;
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                var res = nodeA.data.dataUltimaComunicacaoEmMili == nodeB.data.dataUltimaComunicacaoEmMili ? 0 : nodeA.data.dataUltimaComunicacaoEmMili > nodeB.data.dataUltimaComunicacaoEmMili ? 1 : -1;
                return res;
              },
            },         
            { headerName: 'Ignição', field: 'ignicao'},           
            { headerName: 'Problema', field: 'problemaVeiculo' }                       
          ]
      },
      popUpOcorrencia: {
        exibir: false,
        dataHoraContatoComCliente: null,
        nomeContato: null,
        telefoneContato: null,
        emManutencao: null,
        descricao: null,
        tratativa: null,
        respostaCliente: null,
        desconsiderarAte: null
      },
      popUpListaOcorrencia: {
        exibir:false,
        listaUltimasOcorrencias: [],
        placaSelecionada: null
      },
      qtdRegistrosSelecionados: 0,
      configDatePicker: {          
          altInput: true,
          altFormat: "d-m-Y",
          dateFormat: "Ymd", 
          altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
          locale: PortugueseLocale
      },
      configDateTimePicker: {          
          altInput: true,
          altFormat: "d-m-Y H:i",
          dateFormat: "YmdHiS", 
          altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
          locale: PortugueseLocale,
          enableTime: true,
          minuteIncrement: 1
      },
      publicPath: process.env.BASE_URL
    }
  },  
  created() {
    this.listaOpcoesExibir = CONSTANTE_OPCAO_EXIBIR;
    this.listaOpcoesSIM_NAO = CONSTANTE_OPCAO_SIM_NAO;
  },
  computed: {
      
  },
  methods: { 
    exibirPopUpListaOcorrencias(){
        var selectedRows = this.configuracaoGrid.gridApi.getSelectedRows();
        var placaSelecionada = selectedRows[0].placa;
        this.popUpListaOcorrencia.listaUltimasOcorrencias = [];
        this.popUpListaOcorrencia.exibir = true;      
        this.popUpListaOcorrencia.placaSelecionada = placaSelecionada
        this.fetchUltimasOcorrencias(placaSelecionada);
    },
    gravar() {
        if (!this.formularioValido()) {
            return;            
        }
        this.$vs.loading();  
        const params = new URLSearchParams();  
        
        var idsVeiculos = null;
        var placasVeiculos = null;
        var idsClientes = null;

        var listaVeiculoSelecionados = this.configuracaoGrid.gridApi.getSelectedRows();

        for (var i= 0; i < listaVeiculoSelecionados.length; i++){
            if (idsVeiculos === null) {
                idsVeiculos = new String();
                placasVeiculos = new String();
                idsVeiculos = idsVeiculos + listaVeiculoSelecionados[i].idVeiculo;
                placasVeiculos = placasVeiculos + listaVeiculoSelecionados[i].placa;
                idsClientes = idsClientes + listaVeiculoSelecionados[i].idCliente;
            } else {
                idsVeiculos = idsVeiculos + "," + listaVeiculoSelecionados[i].idVeiculo;
                placasVeiculos = placasVeiculos + "," + listaVeiculoSelecionados[i].placa;
                idsClientes = idsClientes + "," + listaVeiculoSelecionados[i].idCliente;
            }
        }

        params.append('acao', 'REGISTRAR_OCORRENCIAS_EM_LOTE');
        params.append('idsVeiculos', idsVeiculos); 
        params.append('placasVeiculos', placasVeiculos); 
        params.append('idsClientes', idsClientes); 

        params.append('dataHoraContatoComCliente', this.popUpOcorrencia.dataHoraContatoComCliente); 
        params.append('nomeContato', this.popUpOcorrencia.nomeContato); 
        params.append('telefoneContato', this.popUpOcorrencia.telefoneContato); 
        if (this.popUpOcorrencia.emManutencao) {
          params.append('emManutencao', this.popUpOcorrencia.emManutencao); 
        }
        params.append('descricao', this.popUpOcorrencia.descricao); 
        params.append('tratativa', this.popUpOcorrencia.tratativa); 
        params.append('respostaCliente', this.popUpOcorrencia.respostaCliente); 
        if (this.popUpOcorrencia.desconsiderarAte) {
          params.append('desconsiderarAte', this.popUpOcorrencia.desconsiderarAte); 
        }

        axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
        .then((response) => {
          this.efetuandoRequest = false;
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
            if (response.data.mensagem.indexOf('Erro') != -1) {
              this.$refs.errosComponent.iniciar();
              this.errorsValidacao = [];
              this.errorsValidacao.push(response.data.mensagem);              
            } else {
              this.popUpOcorrencia.exibir = false;      
              this.$vs.notify({
                title: "Atenção", text: response.data.mensagem, time: 10000,
                iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
              
              this.fetchLista();
            }
          }
        })
        .catch(() => {           
          this.$vs.loading.close();        
        })  

    },
    exibirPopUpRegistroOcorrencia(){
      var selectedRows = this.configuracaoGrid.gridApi.getSelectedRows();
      this.popUpOcorrencia.exibir = true;      
      let dataFromTS = new Date();
      let dataCorrente = dataFromTS.getUTCFullYear() + generico.zeroPad((dataFromTS.getUTCMonth() + 1),2) + generico.zeroPad(dataFromTS.getUTCDate(),2)
       + generico.zeroPad(dataFromTS.getHours(),2) + generico.zeroPad(dataFromTS.getMinutes(),2)+ generico.zeroPad(dataFromTS.getSeconds(),2);
      
      this.popUpOcorrencia.dataHoraContatoComCliente = dataCorrente;
      this.popUpOcorrencia.descricao = selectedRows[0].problemaVeiculo;
    },
    onGridReady(params) {
      this.configuracaoGrid.gridApi = params.api;
      this.configuracaoGrid.gridColumnApi = params.columnApi;
      this.fetchLista();
    },
    onSelectionChanged() {
      var selectedRows = this.configuracaoGrid.gridApi.getSelectedRows();
      this.qtdRegistrosSelecionados = selectedRows.length;      
    },
    compararDatas(dataEmMili1, dataEmMili2) {
        if (dataEmMili1 === null && dataEmMili2 === null) {
          return 0;
        }
        if (dataEmMili1 === null) {
          return -1;
        }
        if (dataEmMili2 === null) {
          return 1;
        }

        return dataEmMili1 - dataEmMili2;
    },
    exportarParaCSV() {
      var params = {columnSeparator: ';', fileName: 'RelatorioModulosComProblema.csv'};
      this.configuracaoGrid.gridApi.exportDataAsCsv(params);
    },
    fetchLista() {     
      this.$vs.loading();
      const params = new URLSearchParams();
      params.append('outputFormat', 'JSON');
      params.append('pagina', -1);
      params.append('quantidadeHoras', this.numeroHoras);
      if (this.exibirDesconsideradosAte) {
        params.append('exibirDesconsideradosAte', ( this.exibirDesconsideradosAte === 'SIM' ? true : false));
      }

      if (this.nomeCliente) {
        params.append('nomeCliente', this.nomeCliente);
      }

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.efetuandoRequest = false;
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.dadosDaGrid = response.data;                    
          }

          this.configuracaoGrid.gridApi.sizeColumnsToFit();    
      })
      .catch((error) => { 
          this.efetuandoRequest = false;
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
    },
    formularioValido() {
            this.$refs.errosComponent.iniciar();
            this.errorsValidacao = [];

            if (!this.popUpOcorrencia.dataHoraContatoComCliente) { this.errorsValidacao.push("Data e Hora de Contato com o cliente obrigatório."); }
            if (!this.popUpOcorrencia.nomeContato) { this.errorsValidacao.push("Nome do Contato obrigatório"); }
            if (!this.popUpOcorrencia.telefoneContato) { this.errorsValidacao.push("Telefone do Contato obrigatório"); }
            if (!this.popUpOcorrencia.descricao) { this.errorsValidacao.push("Descrição obrigatória"); }
            if (!this.popUpOcorrencia.tratativa) { this.errorsValidacao.push("Tratativa obrigatória"); }
            if (!this.popUpOcorrencia.respostaCliente) { this.errorsValidacao.push("Resposta do cliente obrigatória"); }
            
            if (!this.errorsValidacao.length) {
                return true;
            }   
        
            return false;
    },
    fetchUltimasOcorrencias(pPlaca) {     
      const params = new URLSearchParams();
      params.append('pagina', -1);
      params.append('veiculo.placa', pPlaca);
      
      axios.post("/rest/VeiculoHistorico/Listar", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.totalRegistros = response.data.Resultado.quantidade;
              if (response.data.Resultado.quantidade > 1) {
                  this.popUpListaOcorrencia.listaUltimasOcorrencias = response.data.Resultado.lista.item;
              } else if (response.data.Resultado.quantidade === 1) {
                  this.popUpListaOcorrencia.listaUltimasOcorrencias = [];
                  this.popUpListaOcorrencia.listaUltimasOcorrencias.push(response.data.Resultado.lista.item);
              } else {
                  this.popUpListaOcorrencia.listaUltimasOcorrencias = [];
              }                   
          }
      })
      .catch((error) => {           
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    },
  },
  mounted() {  
    if (this.filtroHoras) {
      this.numeroHoras = this.filtroHoras;
    }
  },
  beforeDestroy() {
    
  },
  beforeMount(){
    this.tipoSelecaoLinha = 'multiple';
  }
}

</script>

<style lang="scss">
  .ag-grid-altura{
    height: calc(100vh - 185px);
  }

  .ag-grid-table-com-selecao {
      .ag-row-selected {
        background-color: #06f067 !important;
        color: rgb(0, 0, 0);        
      }

      .ag-ltr .ag-cell-focus {
          border: 1px solid #06f067 !important;
      }
  }

</style>